import React, { useState, useEffect } from "react";
import MobileLayout from "../MobileLayout";
import { AppBar, Box, Button, makeStyles, Toolbar, Typography } from "@material-ui/core";
import BackIcon from "@material-ui/icons/ArrowBack";
import { useNavigate, useLocation } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import breeferPlayButton from "../asset/icons/breeferPlayButton.png";
import Logo from "../asset/logo/LOGO_ORANGE.svg";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useMediaQuery } from "@material-ui/core";
import "../App.css";
import mixpanel from "../mixpanel/mixpanelWrapper";

const textArray = [
  "Great. You are almost on target. Press play and see how high a tower can you build.",
  "Great. You are almost on target. Press play and see if you can win in stone scissors & paper.",
  "Great. You are almost on target. Press play and see how many times you can frame.",
];
const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "transparent",
    boxShadow: "none",
  },
}));
const StartAGame = () => {
  const isMobile = useMediaQuery("(max-height:650px)");
  const isTablet = useMediaQuery("(max-height:1024px)");
  const classes = useStyles();
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);
  const [isTracked, setIsTracked] = useState(false);
  const userId: any = localStorage.getItem("InvitedUserId");

  const breefDetails = useSelector((state: RootState) => state.score.breefDetails);
  console.log("breefDetails", breefDetails);
  console.log("is Mobile", isMobile);

  useEffect(() => {
    if (!isTracked) {
      mixpanel.track("Game Intro Viewed", {
        breef_id: breefDetails?.id,
        tenant_id: breefDetails?.tenantId.id,
        user_id: userId,
        number_of_qustions: breefDetails?.questions.length,
        start_date: breefDetails?.period,
        expiry_date: breefDetails?.expiry,
        video_url: breefDetails?.videoUrl,
        current_page: "game-info",
        game_name: breefDetails?.gameId.gameName,
      });
      setIsTracked(true);
    }
  }, [isTracked]);

  const handleNext = () => {
    // if (index < textArray.length - 1) {
    //   setIndex(index + 1);
    // } else {
    //   console.log('End reached');
    // navigate('/game-scene', { state: { totalQuestions: location.state.totalQuestions , score: location.state.score , breefDetails,duration } }); // Access location state
    navigate("/game-scene");
    // }
  };

  return (
    <MobileLayout>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar>{/* <img src={Logo} alt="logo" height="80 rem" /> */}</Toolbar>
      </AppBar>
      {/* <Box display="flex" justifyContent="flex-start" alignItems="center" mt={"3%"}>
        <Button variant="text" startIcon={<BackIcon />} style={{ marginLeft: '3%', marginTop: '3%', textTransform: 'none' }} onClick={() => navigate('/video')}>
          Video
        </Button>
      </Box> */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
        className="container"
      >
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <Box style={{ marginTop: "20%", width: "100%" }}>
            <Typography
              variant="h3"
              style={
                isMobile
                  ? { marginLeft: "15%", marginRight: "12%", fontWeight: "350", fontSize: "2.2rem" }
                  : { marginLeft: "15%", marginRight: "12%", fontWeight: "350" }
              }
              align="left"
            >
              {t("gameDesc.desc1")}
              <span
                onClick={() => {
                  handleNext();
                  mixpanel.track("Game Intro next button clicked", {
                    breef_id: breefDetails?.id,
                    tenant_id: breefDetails?.tenantId.id,
                    user_id: userId,
                    number_of_qustions: breefDetails?.questions.length,
                    start_date: breefDetails?.period,
                    expiry_date: breefDetails?.expiry,
                    video_url: breefDetails?.videoUrl,
                    current_page: "game-info",
                    game_name: breefDetails?.gameId.gameName,
                  });
                }}
                style={{ color: "#ff6a20", fontWeight: "bold", cursor: "pointer", fontFamily:"Roboto" }}
              >
                {t("gameDesc.desc2")}
              </span>
              {t("gameDesc.desc3")} {breefDetails.gameId.gameDescription}
            </Typography>
          </Box>
        </Box>

        <Box
          height="10%"
          style={{
            position: "absolute",
            bottom: "5rem",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          {/* <Button variant="contained" style={{ backgroundColor: '#EB6608', color: '#FFFFFF', width: '100%' }} onClick={handleNext}>
          {index < textArray.length - 1 ? 'Next' : 'Play'}
        </Button> */}
          <div
            style={{ border: "none" }}
            onClick={() => {
              handleNext();
              mixpanel.track("Game Intro next button clicked", {
                breef_id: breefDetails?.id,
                tenant_id: breefDetails?.tenantId.id,
                user_id: userId,
                number_of_qustions: breefDetails?.questions.length,
                start_date: breefDetails?.period,
                expiry_date: breefDetails?.expiry,
                video_url: breefDetails?.videoUrl,
                current_page: "game-info",
                game_name: breefDetails?.gameId.gameName,
              });
            }}
          >
            <img
              src={breeferPlayButton}
              alt="play"
              style={
                isMobile
                  ? { cursor: "pointer", border: "none", height: "65px" }
                  : { cursor: "pointer", border: "none", height: "65px" }
              }
            />
          </div>
        </Box>
      </Box>
    </MobileLayout>
  );
};

export default StartAGame;
