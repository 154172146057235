import React from "react";
import { Route, Routes } from "react-router";
import { Introduction } from "./introduction/IntroductionSceneOne";
import Quiz from "./quiz/Quiz";
import StartAGame from "./game/StartAGame";
import GameScenePage from "./game/GameScene";
import GameResult from "./game/GameResult";
import ProfilePage from "./profile/Profile";
import ChangePasswordPage from "./profile/ChangePassword";
import PasswordChangedPage from "./profile/PasswordChanged";
import Signup from "./login/Signup";
import ForgotPasswordPage from "./login/ForgotPassword";
import SplashScreen from "./splash/SplashScreen";
import { HomeSection } from "./home-section/Home";
import { VideoScene } from "./video-section/WatchMovie";
import ResetPasswordPage from "./profile/ResetPassword";
import PrivateRoutes from "./ProtectedRoutes";
import ErrorPage from "./error/ErrorPage";
import View from "./home-section/View";
import { CheckBreefExpiryOrSubmitted } from "./introduction/checkBreefExpiryOrSubmitted";
import QuizInstruction  from "./video-section/QuizInstruction";
import { HomeSection2 } from "./home-section/Home2";
import Login from "./login/Login";
export const Router = () => {
  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route path="/reset-password" element={<ResetPasswordPage />}></Route>
        <Route path="/change-password" element={<ChangePasswordPage />}></Route>
        <Route path="/password-changed" element={<PasswordChangedPage />}></Route>
        <Route path="/home2" element={<HomeSection />}></Route>
        <Route path="/home" element={<HomeSection2 />}></Route>
        <Route path="/profile" element={<ProfilePage />}></Route>
      </Route>

      <Route path="/no-access" element={<CheckBreefExpiryOrSubmitted />}></Route>
      <Route path="/error" element={<ErrorPage />}></Route>
      <Route path="/quiz-info" element={<QuizInstruction />}></Route>
      <Route path="/breefInvite" element={<Introduction />}></Route>
      <Route path="/quiz" element={<Quiz />}></Route>
      <Route path="/game" element={<StartAGame />}></Route>
      <Route path="/game-scene" element={<GameScenePage />}></Route>
      <Route path="/game-result" element={<GameResult />}></Route>
      <Route path="/sign-up" element={<Signup />}></Route>
      <Route path="/sign-in" element={<Login />}></Route>
      <Route path="/forgot-password" element={<ForgotPasswordPage />}></Route>
      <Route path="/" element={<SplashScreen />}></Route>
      <Route path="/video" element={<VideoScene />}></Route>
      <Route path="/view" element={<View />}></Route>
    </Routes>
  );
};
