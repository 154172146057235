import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography, FormControl, Input, Button, Link } from "@material-ui/core";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import MobileLayout from "../MobileLayout";
import { useNavigate } from "react-router";
import logo from "../asset/logo/LOGO_ORANGE.svg";
import arrowRightWhite from "../asset/icons/rightarrow.png";
import { registerUser } from "./RegisterService"; // Import the service
import { setFirstName, setLastName, setEmail, setPhone, setUserID } from "./slice";
import { useDispatch } from "react-redux";
import { Alert } from "@mui/material";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import { useTranslation } from "react-i18next";
import mixpanel from "../mixpanel/mixpanelWrapper";
import { Phone } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "transparent",
    boxShadow: "none",
    display: "flex",
    justifyContent: "center",
  },
  backButton: {
    color: "black",
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: "black",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  },
  formHeader: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(5),
    width: "100%",
    textAlign: "left",
  },
  formContainer: {
    display: "flex",
    overflowY: "hidden",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
    paddingBottom: theme.spacing(10),
  },
  form: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    paddingBottom: theme.spacing(10),
  },
  formControl: {
    margin: theme.spacing(1),
    width: "85%",
  },
  inputRoot: {
    backgroundColor: "white",
    borderRadius: "10px",
    boxShadow: "0 0 1px black",
    padding: "0 20px",
    height: "3rem",
  },
  submitButton: {
    backgroundColor: "#EB6608",
    color: "white",
    width: "85%",
    marginTop: theme.spacing(2),
    borderRadius: "10px",
    height: "3rem",
    position: "relative",
  },
  linkText: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
    width: "80%",
    justifyContent: "flex-end", // Align items to the end
  },
  signInLink: {
    marginLeft: theme.spacing(1),
    color: "#EB6608",
    cursor: "pointer",
  },
  logo: {
    width: "auto",
    height: "10rem",
  },
  toolbar: {
    display: "flex",
    justifyContent: "center",
  },
  errorMessage: {
    marginTop: "2px",
    color: "red",
  },
}));

const SignupPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [activeField, setActiveField] = useState("");

  const firstName = localStorage.getItem("firstName");
  const lastName = localStorage.getItem("lastName");
  const email = localStorage.getItem("email");
  const phoneNumber = localStorage.getItem("phone");
  const tenantId = localStorage.getItem("tenantId");

  console.log(firstName, lastName, email, phoneNumber, tenantId);

  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("error");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [isTracked, setIsTracked] = useState(false);

  useEffect(() => {
    if (!isTracked) {
      mixpanel.track("Signup page viewed", {
        current_page: "signup",
      });
      setIsTracked(true);
    }
  }, [isTracked]);

  const handleClose = (
    event: Event | React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const initialValues = {
    firstName: firstName || "",
    lastName: lastName || "",
    phoneNumber: phoneNumber || "",
    email: email || "",
    password: "",
    confirmPassword: "",
    tenantId: tenantId || "",
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    phoneNumber: Yup.string()
      .matches(/^[2-9][0-9]{7}$/, ("Phone number is not valid"))
      .min(8, "Phone number is not valid")
      .max(8, "Phone number is not valid")
      .required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().min(8, "Password must be at least 8 characters").required("Required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password")], "Passwords must match")
      .required("Required"),
  });

  const handleFocus = (fieldName: string) => {
    setActiveField(fieldName);
  };

  const handleBlur = (
    fieldName: string,
    setFieldTouched: (fieldName: string, touched?: boolean, shouldValidate?: boolean) => void
  ) => {
    setFieldTouched(fieldName, true);
    setActiveField("");
  };

  const handleSignInClick = () => {
    navigate("/sign-in");
  };
  const [t, i18n] = useTranslation("global");
  return (
    <MobileLayout>
      <AppBar position="static" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <img src={logo} alt="logo" className={classes.logo} />
        </Toolbar>
      </AppBar>
      <Typography variant="body1" className={classes.formHeader}>
        {t("signup.title")}
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const teamIds: any = localStorage.getItem("teamId");
            const { confirmPassword, ...userData } = values;
            const response = await registerUser({
              ...userData,
              teamIds: teamIds.split(","),
            });
            mixpanel.alias(response.user.id);
            mixpanel.identify(response.user.id);
            mixpanel.track("Signup Button Clicked", {
              first_name: userData.firstName,
              last_name: userData.lastName,
              email: userData.email,
              phone: userData.phoneNumber,

              current_page: "signup",
            });
            mixpanel.people.set({
              id: response.user.id,
              $first_name: userData.firstName,
              $last_name: userData.lastName,
              $email: userData.email,
              $phone: userData.phoneNumber,

              current_page: "signup",
            });

            console.table(response);
            console.log(response.user.phoneNumber);
            localStorage.clear();
            localStorage.setItem('userId', response.user.id);
            localStorage.setItem('accessToken', response.tokens.access.token);
            localStorage.setItem('refreshToken', response.tokens.refresh.token);
            localStorage.setItem('user', JSON.stringify(response.user));
            dispatch(setFirstName(response.user.firstName));
            dispatch(setLastName(response.user.lastName));
            dispatch(setEmail(response.user.email));
            dispatch(setPhone(response.user.phoneNumber));
            dispatch(setUserID(response.user.id));

            navigate("/home");
          } catch (err: any) {
            console.error("Registration failed", err);
            setErrorMessage(err.response.data.message);
            setSnackbarOpen(true);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ errors, touched, handleSubmit, handleChange, handleBlur, isSubmitting }) => (
          <Form className={classes.formContainer} onSubmit={handleSubmit}>
            <div className={classes.form}>
              <FormControl className={classes.formControl}>
                <Field name="firstName">
                  {({
                    field,
                  }: {
                    field: {
                      value: string;
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
                      onBlur: () => void;
                    };
                  }) => (
                    <Input
                      {...field}
                      placeholder={t("signup.fn")}
                      onBlur={() => handleBlur("firstName")}
                      onFocus={() => handleFocus("firstName")}
                      classes={{ root: classes.inputRoot }}
                      disableUnderline
                      autoComplete="off"
                    />
                  )}
                </Field>
                <ErrorMessage name="firstName" component="div" className={classes.errorMessage} />
              </FormControl>
              <FormControl className={classes.formControl}>
                <Field name="lastName">
                  {({
                    field,
                  }: {
                    field: {
                      value: string;
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
                      onBlur: () => void;
                    };
                  }) => (
                    <Input
                      {...field}
                      placeholder={t("signup.ln")}
                      onBlur={() => handleBlur("lastName")}
                      onFocus={() => handleFocus("lastName")}
                      classes={{ root: classes.inputRoot }}
                      disableUnderline
                      autoComplete="off"
                    />
                  )}
                </Field>
                <ErrorMessage name="lastName" component="div" className={classes.errorMessage} />
              </FormControl>
              <FormControl className={classes.formControl}>
                <Field name="phoneNumber">
                  {({
                    field,
                  }: {
                    field: {
                      value: string;
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
                      onBlur: () => void;
                    };
                  }) => (
                    <Input
                      {...field}
                      placeholder={t("signup.pn")}
                      onBlur={() => handleBlur("phoneNumber")}
                      onFocus={() => handleFocus("phoneNumber")}
                      classes={{ root: classes.inputRoot }}
                      disableUnderline
                      autoComplete="off"
                    />
                  )}
                </Field>
                <ErrorMessage name="phoneNumber" component="div" className={classes.errorMessage} />
              </FormControl>
              <FormControl className={classes.formControl}>
                <Field name="email">
                  {({
                    field,
                  }: {
                    field: {
                      value: string;
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
                      onBlur: () => void;
                    };
                  }) => (
                    <Input
                      {...field}
                      placeholder="name@host.com"
                      onBlur={() => handleBlur("email")}
                      onFocus={() => handleFocus("email")}
                      classes={{ root: classes.inputRoot }}
                      disableUnderline
                      autoComplete="off"
                    />
                  )}
                </Field>
                <ErrorMessage name="email" component="div" className={classes.errorMessage} />
              </FormControl>
              <FormControl className={classes.formControl}>
                <Field name="password" type="password">
                  {({
                    field,
                  }: {
                    field: {
                      value: string;
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
                      onBlur: () => void;
                    };
                  }) => (
                    <Input
                      {...field}
                      placeholder={t("signup.pass")}
                      type="password"
                      onBlur={() => handleBlur("password")}
                      onFocus={() => handleFocus("password")}
                      classes={{ root: classes.inputRoot }}
                      disableUnderline
                      autoComplete="off"
                    />
                  )}
                </Field>
                <ErrorMessage name="password" component="div" className={classes.errorMessage} />
              </FormControl>
              <FormControl className={classes.formControl}>
                <Field name="confirmPassword" type="password">
                  {({
                    field,
                  }: {
                    field: {
                      value: string;
                      onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
                      onBlur: () => void;
                    };
                  }) => (
                    <Input
                      {...field}
                      placeholder={t("signup.repass")}
                      type="password"
                      onBlur={() => handleBlur("confirmPassword")}
                      onFocus={() => handleFocus("confirmPassword")}
                      classes={{ root: classes.inputRoot }}
                      disableUnderline
                      autoComplete="off"
                    />
                  )}
                </Field>
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className={classes.errorMessage}
                />
              </FormControl>
              <div
                className={classes.linkText}
                style={{ justifyContent: "flex-start", alignItems: "center" }}
              >
                <Typography variant="body1">{t("signup.acc")}</Typography>
                <Link className={classes.signInLink} onClick={handleSignInClick}>
                  {t("signup.signin")}
                </Link>
              </div>
              <Button
                type="submit"
                variant="contained"
                className={classes.submitButton}
                disabled={isSubmitting}
              >
                {t("signup.signup")}
                <img
                  src={arrowRightWhite}
                  alt="arrow"
                  style={{ height: "12px", width: "12px", position: "absolute", right: "15px" }}
                />
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity={snackbarSeverity}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </MobileLayout>
  );
};

export default SignupPage;
