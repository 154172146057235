import React, { useEffect, useState } from "react";
import MobileLayout from "../MobileLayout";
import OrangeAlien from "../asset/characters/OrangeAlien.png";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  ThemeProvider,
  createTheme,
} from "@material-ui/core";
import breeferIcon from "../asset/icons/breeferButtonIcon.png";
import breeferLogo from "../asset/logo/LOGO_ORANGE.svg";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { getUserOrInvitedUser } from "../lib/userDetails/userDetailsService";
import { RootState } from "../redux/store";
import BreefReportService from "./BreefReportService";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import IntroductionAlien from "../asset/characters/introductionAlien.png";
import mixpanel from "../mixpanel/mixpanelWrapper";


const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GameResult = () => {
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success");
  const [breefCount, setBreefCount] = useState<number>(0);
  const userId: any = localStorage.getItem("InvitedUserId");
  const [isTracked, setIsTracked] = useState(false);
  const breefDetails = useSelector((state: RootState) => state.score.breefDetails);
  const gameScore = useSelector((state: RootState) => state.score.points);

  useEffect(() => {
    if (!isTracked) {
      mixpanel.track("Breef score page viewed", {
        breef_id: breefDetails?.id,
        tenant_id: breefDetails?.tenantId.id,
        user_id: userId,
        number_of_qustions: breefDetails?.questions.length,
        start_date: breefDetails?.period,
        expiry_date: breefDetails?.expiry,
        video_url: breefDetails?.videoUrl,
        current_page: "breef-score",
        game_name: breefDetails?.gameId.gameName,
        quiz_score:quizScore,
        game_score:gameScore,
        breef_score:quizScore+gameScore,
      });
      setIsTracked(true);
    }
  }, [isTracked]);



  const handleClose = (
    event: Event | React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const [isInitialLoad, setIsInitialLoad] = useState(true); // Track initial load

  useEffect(() => {
    if (isInitialLoad) {
      if (sessionStorage.getItem("pageReloaded") === "true") {
        console.log("first");
        navigate("/error");
      }
    }
  }, [isInitialLoad, navigate]);

  const theme = createTheme(); // Create a default theme
  // USER ID
  const newUser = useSelector((state: RootState) => state.onboard.newUser);

  const points = useSelector((state: RootState) => state.score.points);
  const duration = useSelector((state: RootState) => state.score.duration);
  const quizScore = useSelector((state: RootState) => state.score.quizScore);
  const totalScore = quizScore + points;

  console.log(
    "duration: ",
    duration,
    "quizScore: ",
    quizScore,
    "breefScore: ",
    points,
    "totalScore: ",
    totalScore
  );

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [name, setName] = useState(localStorage.getItem("firstName"));
  const [userType, setUserType] = useState<string | null>(null);
  const [userData, setUserData] = useState<any>(null);

  const fetchData = () => {
    try {
      console.log("Passing the value as :", newUser);
      const { data, userType } = getUserOrInvitedUser(newUser);
      setUserData(data);
      setUserType(userType);
    } catch (error) {
      console.error(error);
    }
  };

  const quizChances = useSelector((state: RootState) => state.score.quizChances);
  console.log(quizChances);
  useEffect(() => {
    const handleButtonClick = async () => {
      const access = localStorage.getItem("accessToken");
      fetchData();
      const quizScoreLs: any = localStorage.getItem("quizScore");
      try {
        // await BreefReportService.createBreefReport(parseInt(quizScoreLs), duration, totalScore, quizChances, setSnackbarMessage, setSnackbarSeverity, setSnackbarOpen);
        await BreefReportService.updateBreefTotalScore(
          totalScore,
          setSnackbarMessage,
          setSnackbarSeverity,
          setSnackbarOpen
        );
      } catch (error: any) {
        console.error("Error updating breef report:", error);
      }

      try {
        const breefCountResponse: any = await BreefReportService.getBreefCountByUserId();
        console.log("breef count", breefCountResponse);
        setBreefCount((breefCountResponse && breefCountResponse.numberOfBreefsSubmitted) || 0);
      } catch (error) {
        console.log("Error in fetching breef count", error);
      }

      setTimeout(async () => {
        if (newUser) {
          navigate("/sign-up", { state: userData });
        } else {
          navigate("/sign-in");
        }
      }, 10000);

      console.log("snackbarMessage", snackbarMessage);
    };
    handleButtonClick();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <MobileLayout>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box justifyContent="center" mt={"5%"} width={"100%"}>
            <img
              src={IntroductionAlien}
              alt="Orange Alien"
              style={{
                width: "200px",
                height: "200px",
                display: "block",
                margin: isMobile ? "0 auto" : "0 auto",
              }}
            />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
          <Typography variant="h4" component="h4" align="center">
            Congratulations <span style={{ color: "#EB6608" }}>{name}</span>.
          </Typography>
          <Typography variant="h4" component="h4" align="center">
            Your score is:
          </Typography>
          {totalScore && (
            <Typography
              variant="h2"
              component="h2"
              align="center"
              style={{ fontSize: "5rem", fontWeight: "500", marginTop: "1rem" }}
            >
              {totalScore}
            </Typography>
          )}
          {breefCount && (
            <Typography
              variant="body1"
              component="p"
              align="center"
              style={{ fontSize: "1.2rem", marginTop: "1rem" }}
            >
              {`You are on target with ${breefCount} breefs. Can you do better?`}
            </Typography>
          )}
          <Box
            style={{
              width: isMobile ? "80%" : "25%",
              position: "absolute",
              bottom: "5rem",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <Button
              variant="text"
              style={{
                background: "none",
                border: "none",
                padding: 0,
                margin: 0,
                width: "100%",
                textTransform: "none",
              }}
              // onClick={handleButtonClick}
            >
              {/* <img src={breeferIcon} alt="Breefer Icon" style={{ marginRight: '1px' }} />
              Continue */}
              <img src={breeferLogo} alt="Breefer Logo" style={{ width: "60%", height: "60%" }} />
            </Button>
          </Box>
        </Box>
        {/* <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleClose} severity={snackbarSeverity}>
            {snackbarMessage}
          </Alert>
        </Snackbar> */}
      </MobileLayout>
    </ThemeProvider>
  );
};

export default GameResult;
